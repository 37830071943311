import { sub } from 'date-fns';
//
import { role } from './role';
import { email } from './email';
import { cover } from './cover';
import { boolean } from './boolean';
import { company } from './company';
import { phoneNumber } from './phoneNumber';
import { fullAddress, country } from './address';
import { firstName, lastName, fullName } from './name';
import { title, sentence, description } from './text';
import { price, rating, age, percent } from './number';

// ----------------------------------------------------------------------

const _mock = {
  id: (index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
  email: (index) => email[index],
  phoneNumber: (index) => phoneNumber[index],
  time: (index) => sub(new Date(), { days: index, hours: index }),
  boolean: (index) => boolean[index],
  role: (index) => role[index],
  cover: (index) => cover[index],
  company: (index) => company[index],
  address: {
    fullAddress: (index) => fullAddress[index],
    country: (index) => country[index],
  },
  name: {
    firstName: (index) => firstName[index],
    lastName: (index) => lastName[index],
    fullName: (index) => fullName[index],
  },
  text: {
    title: (index) => title[index],
    sentence: (index) => sentence[index],
    description: (index) => description[index],
  },
  number: {
    percent: (index) => percent[index],
    rating: (index) => rating[index],
    age: (index) => age[index],
    price: (index) => price[index],
  },
  image: {
    cover: (index) => `https://minimal-assets-api.vercel.app/assets/images/covers/cover_${index + 1}.jpg`,
    feed: (index) => ['https://lh3.googleusercontent.com/pw/ADCreHf_4vvYy8z5iXTIh9FiGT1-zyYdCAQ3Y-pJNE89JiJoZQhMcgcN-TxPKehAAz4sp3dB3eyHbUHuGYU38iRU3bTPT687fX-9xRKVbVyPya2GELR_O9tSX_FFNoUG_Ucj14M1mrdsFJGtsfCvCSEs5VmlNw=w815-h611-s-no?authuser=0', 'https://lh3.googleusercontent.com/pw/ADCreHfJME7z_QKZA9sVoC_5jrjCI9KaVXRArNhqD5Np8CRT4qiHJCNPQUxmtQ6wI66FnxoK3iR8sqxaFx8TdMQ1sdy3fww_xsMY5iGy9NpufFIKSiEUjSC6crJ0NracOC_TxzVB2AqfeYdtPGhc6THZL5aKmA=w815-h611-s-no?authuser=0', 'https://lh3.googleusercontent.com/pw/ADCreHdC5sSMlj8dbsNKwR8RKK7xngrG869FuElTwoQ94JxvKHWRsGHfwcqxb-op7UqUuj0Qcoi_oYHYcCzAEdIUvSKk1gK7Vhyw0zb0LFDB1eSF-AXlt-Oh9q4KBroj0h-l2lqQl9S4g5eD_bVFzzTXCF3t_A=w815-h611-s-no?authuser=0'],
    product: (index) => `https://minimal-assets-api.vercel.app/assets/images/products/product_${index + 1}.jpg`,
    avatar: (index) => `https://minimal-assets-api.vercel.app/assets/images/avatars/avatar_${index + 1}.jpg`,
  },
};

export default _mock;
