// ----------------------------------------------------------------------

export const cover = [
    'https://lh3.googleusercontent.com/pw/AIL4fc93LML262tt0jCbEm-82EXtepQGGWV3dkus7UTGqDtFoKbNyOO3S5WHb93asb3ZOoctUeGU4DVrmoWXcj6Y9hz9nKRw-dtez4smlTILvEm1SMjwN1C1dED-fh98cRFvIqNyT1iBn9_ErzibQ_0kVEmS=w1358-h611-s-no?authuser=0',
    'https://lh3.googleusercontent.com/pw/AIL4fc-YiwDOLMXliCPldrW5lc08uR6eK8DbsIceRowBb2Rw14f80h5KNtpARAiEuUP4bkznZ_EQKGlpml04yv8v0RxBKTo2Boi7sRgyD-62vOuWwBtAtmzFzEkvgEgOV-Op7jAxoA-uWFGSN-OOIHqfyUb6=w1358-h611-s-no?authuser=0',
    'https://lh3.googleusercontent.com/pw/AIL4fc_TSKewshfsRoSJJ37dCUq8er1rf4Njd6HmIWr426wa7dtoREDw2oJx9WvUVuuB4bIN5Gp42rH7z-67WD1XRn1AGaYTRYHIy_AMn4c-xDewhnMUf6N5kymZx7kWv6GDVGO9dsjv4_FP05l86qTVvxcQ=w1358-h611-s-no?authuser=0',
    'https://lh3.googleusercontent.com/pw/AIL4fc_TSKewshfsRoSJJ37dCUq8er1rf4Njd6HmIWr426wa7dtoREDw2oJx9WvUVuuB4bIN5Gp42rH7z-67WD1XRn1AGaYTRYHIy_AMn4c-xDewhnMUf6N5kymZx7kWv6GDVGO9dsjv4_FP05l86qTVvxcQ=w1358-h611-s-no?authuser=0',
    'https://lh3.googleusercontent.com/pw/AIL4fc8hXCQyJ5oNLiMd14F2mbvI5yovHc6OYTPe4wXaUFlyEiSgTAbXJwXO6yPCcqSJZnJWWGoI873bPHzxhdq36e6i2XtVkTxZXxWEFs9FHRZin364k_mo6_Ncd6n1KZFvA_Ffm5ruNgpaIUWUlvX5W6qA=w1358-h611-s-no?authuser=0',
    'https://lh3.googleusercontent.com/pw/AIL4fc9oNEZY4ZWSxqyQxrdYpjmEYqbeaH0rBhZcajcVglSSlaeAVicRI3iSe8k79QYdkgYj4S_jHw5JQE1CC-722Oj9XRp2cnPCetTHsGwxtT43zTxS-KUuKm6hTy9bZCKHKjJR1-CIsiHXCcpABlyk6uVj=w1358-h611-s-no?authuser=0',
    ''
  ];
  